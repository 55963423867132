
.product-detail-media-gallery {
    width: 100%;
    max-width: 100dvw;
    margin-bottom: 16px;
}

.gallery-grid-container {
    display: none;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    gap: 32px 16px;

    @media screen and (min-width: 768px) {
            display: grid;
    }
}

.-big-image {
    grid-column: 1 / -1;
}

.placeholder-image {
    height: 200px;
}

.gallery-swiper-container {
    position: relative;
    width: 100%;
    max-width: 100dvw;
    overflow: hidden;

    @media screen and (min-width: 768px) {
        display: none;
    }
}

.gallery-navigator {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 56px;

    .icon {
        width: 16px;
        height: 16px;

        color: $color-theme-grey-3;
    }
}

.gallery-navigator-arrow ::v-deep {
    position: static;
    display: block !important;
}

.image-container {
    min-height: 200px;
    position: relative;
}

.image {
    width: 100%;
    height: 100%;
    cursor: default;

    @media screen and (min-width: 768px) {
        cursor: pointer;
    }

    &:hover + .zoom-image {
        opacity: 1;
    }
}

.zoom-image {
    height: 30px;
    width: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    cursor: pointer;

    opacity: 0;
    position: absolute;
    top: 8px;
    left: 8px;

    background-color: #fff;
    border: 1.3px solid rgba(#121212, 0.1);
    border-radius: 50%;

    transition: all 0.1s;

    &:hover {
        opacity: 1;
    }

    .icon {
        height: 14px;
        width: 12px;
    }
}

@include desktop {
    .product-detail-media-gallery {
        margin-bottom: unset;
    }
}

.video-player-container {
    height: 100%;
}

.swiper-slide-container {
    align-self: center;
}
